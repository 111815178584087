import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const Arrowhead = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title="Arrowhead" language={i18n.language} />
          <div className="arrowhead-page">
              <div className="arrowhead-page-header">
                  <div className="arrowhead-page-header-logo">
                      <div className="arrowhead-page-header-logo-img" />
                  </div>
                  <div className="arrowhead-page-header-vision">
                      <p>{t('arrowhead-vision').toUpperCase()}</p>
                  </div>
              </div>
              <div className="arrowhead-page-prolog">
                  <div className="arrowhead-page-prolog-card">
                      <div className="arrowhead-page-prolog-card-text">
                          <p>{t('arrowhead-prolog')}</p>
                      </div>
                  </div>
              </div>
              <div className="arrowhead-page-section-title"><p>{t('arrowhead-what-is-it').toUpperCase()}</p></div>
              <div className="arrowhead-page-highlight">
                  <iframe src="https://www.youtube.com/embed/vf28cQVgPss" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
              <div className="arrowhead-page-eclipse">
                  <div className="arrowhead-page-eclipse-content">
                      <div className="arrowhead-page-eclipse-content-logo" />
                      <div className="arrowhead-page-eclipse-content-text">
                          <p>{t('arrowhead-eclipse')}</p>
                          <div className="arrowhead-page-eclipse-content-text-links">
                              <a href="https://projects.eclipse.org/projects/iot.arrowhead/governance">Learn more</a>
                              <p>|</p>
                              <a href="https://projects.eclipse.org/projects/iot.arrowhead/developer">Developer resources</a>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="arrowhead-page-principles">
                  <div className="arrowhead-video-info">
                      <div className="arrowhead-video-info-text">
                          <div className="arrowhead-video-info-text-container">
                              <p>{t('arrowhead-nutshell-1')}</p>
                              <br />
                              <p>{t('arrowhead-nutshell-2')}</p>
                          </div>
                      </div>
                      <iframe src="https://www.youtube.com/embed/UZ0fNeK24nI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                  </div>
              </div>
              <div className="arrowhead-page-call">
                  <div className="arrowhead-page-call-content">
                      <div className="arrowhead-page-call-content-logo" />
                      <div className="arrowhead-page-call-content-text">
                          <p>{t('arrowhead-call')}</p>
                      </div>
                  </div>
              </div>
              <div className="arrowhead-page-section-title"><p>{t('arrowhead-how-it-works').toUpperCase()}</p></div>
              <div className="arrowhead-page-overview">
                  <div className="arrowhead-page-overview-content">
                      <div className="arrowhead-page-overview-content-card">
                          <div className="arrowhead-page-overview-content-card-title"><p>{t('arrowhead-local-cloud-title').toUpperCase()}</p></div>
                          <div className="arrowhead-page-overview-content-card-img img-local-cloud" />
                          <div className="arrowhead-page-overview-content-card-text">
                              <p>{t('arrowhead-local-cloud-1')}</p>
                              <p>{t('arrowhead-local-cloud-2')}</p>
                          </div>
                      </div>
                      <div className="arrowhead-page-overview-content-card">
                          <div className="arrowhead-page-overview-content-card-title"><p>{t('arrowhead-inter-cloud-title').toUpperCase()}</p></div>
                          <div className="arrowhead-page-overview-content-card-text">
                              <p>{t('arrowhead-inter-cloud-1')}</p>
                              <p>{t('arrowhead-inter-cloud-2')}</p>
                          </div>
                          <div className="arrowhead-page-overview-content-card-img img-interoperability" />
                      </div>
                      <div className="arrowhead-page-overview-content-video">
                          <div className="arrowhead-video-title">
                              <div className="arrowhead-video-title-text">
                                  <p>{t('arrowhead-basic-principles')}</p>
                              </div>
                              <iframe src="https://www.youtube.com/embed/sssmvX9ltxo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                          </div>
                      </div>
                  </div>
              </div>
              <div className="arrowhead-page-section-title"><p>{t('arrowhead-what-we-do').toUpperCase()}</p></div>
              <div className="arrowhead-page-participation">
                  <div className="arrowhead-page-participation-container">
                      <div className="arrowhead-page-participation-container-card-short">
                          <div className="arrowhead-page-participation-container-card-short-title">
                              <p>{t('arrowhead-proj-mgmt')}</p>
                          </div>
                          <div className="arrowhead-page-participation-container-card-short-icon"><i className="fas fa-tasks" /></div>
                      </div>
                      <div className="arrowhead-page-participation-container-card-short">
                          <div className="arrowhead-page-participation-container-card-short-title">
                              <p>{t('arrowhead-govern')}</p>
                          </div>
                          <div className="arrowhead-page-participation-container-card-short-icon"><i className="fas fa-code-branch" /></div>
                      </div>
                      <div className="arrowhead-page-participation-container-card-short">
                          <div className="arrowhead-page-participation-container-card-short-title">
                              <p>{t('arrowhead-support')}</p>
                          </div>
                          <div className="arrowhead-page-participation-container-card-short-icon"><i className="fas fa-comments" /></div>
                      </div>
                      <div className="arrowhead-page-participation-container-card-long">
                          <div className="arrowhead-page-participation-container-card-long-title">
                              <p>{t('arrowhead-dev-core')}</p>
                          </div>
                          <div className="arrowhead-page-participation-container-card-long-img img-sr" />
                          <div className="arrowhead-page-participation-container-card-long-img img-orch" />
                          <div className="arrowhead-page-participation-container-card-long-img img-auth" />
                          <div className="arrowhead-page-participation-container-card-long-img img-eh" />
                          <div className="arrowhead-page-participation-container-card-long-img img-gk" />
                          <div className="arrowhead-page-participation-container-card-long-img img-gw" />
                          <div className="arrowhead-page-participation-container-card-long-img img-qos" />
                      </div>
                      <div className="arrowhead-page-participation-container-card-info">
                          <div className="arrowhead-page-participation-container-card-info-title">
                              <p>{t('arrowhead-dev-adapter')}</p>
                          </div>
                          <div className="arrowhead-page-participation-container-card-info-img img-adapter" />
                          <div className="arrowhead-page-participation-container-card-info-text">
                              <p>{t('arrowhead-adapter')}</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="arrowhead-page-section-title"><p>{t('arrowhead-productive-title').toUpperCase}</p></div>
              <div className="arrowhead-page-productive">
                  <div className="arrowhead-page-productive-main">
                      <div className="arrowhead-video-info">
                          <iframe src="https://www.youtube.com/embed/EbmO-II3DwQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                          <div className="arrowhead-video-info-text">
                              <div className="arrowhead-video-info-text-container">
                                  <p>{t('arrowhead-productive-1')}</p>
                                  <br />
                                  <p>{t('arrowhead-productive-2')}</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="arrowhead-page-productive-others">
                      <div className="arrowhead-video-title-small">
                          <div className="arrowhead-video-title-small-text">
                              <p>{t('arrowhead-manufacturing')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/v301Y25z2Ss" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                      <div className="arrowhead-video-title-small">
                          <div className="arrowhead-video-title-small-text">
                              <p>{t('arrowhead-machine-mgmt')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/0LHOpdKE5lY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                      <div className="arrowhead-video-title-small">
                          <div className="arrowhead-video-title-small-text">
                              <p>{t('arrowhead-concept')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/8b9bXarESNk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                      <div className="arrowhead-video-title-small">
                          <div className="arrowhead-video-title-small-text">
                              <p>{t('arrowhead-aitia-role')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/v2lhhoUaREA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                  </div>
              </div>
              <div className="arrowhead-page-section-title"><p>{t('arrowhead-learn')}</p></div>
              <div className="arrowhead-page-learn">
                  <div className="arrowhead-page-learn-tutorials">
                      <div className="arrowhead-video-title-smaller">
                          <div className="arrowhead-video-title-smaller-text">
                              <p>{t('arrowhead-build')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/52Up5iDJKx4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                      <div className="arrowhead-video-title-smaller">
                          <div className="arrowhead-video-title-smaller-text">
                              <p>{t('arrowhead-sos-example')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/9BHemnv3mQA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                      <div className="arrowhead-video-title-smaller">
                          <div className="arrowhead-video-title-smaller-text">
                              <p>{t('arrowhead-hello-install')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/t9o7zxrThmE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                      <div className="arrowhead-video-title-smaller">
                          <div className="arrowhead-video-title-smaller-text">
                              <p>{t('arrowhead-hello-scenario')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/N0g_gMo_Y4o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                      <div className="arrowhead-video-title-smaller">
                          <div className="arrowhead-video-title-smaller-text">
                              <p>{t('arrowhead-tool-compliant')}</p>
                          </div>
                          <iframe src="https://www.youtube.com/embed/ELmlBt5ip3Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      </div>
                  </div>
              </div>
              <div className="arrowhead-page-links">
                  <div className="arrowhead-page-links-btn-container">
                      <a className="btn btn--blue" href="https://arrowhead.eu" target="_blank" rel="noreferrer">
                          <i className="fas fa-link">
                              {' arrowhead.eu'}
                          </i>
                      </a>
                  </div>
              </div>
          </div>
      </Layout>
  )
}

export default Arrowhead

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "products", "arrowhead", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
